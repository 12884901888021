// @flow

import * as React from 'react';
import { graphql } from 'gatsby';
import withStyles from '@material-ui/core/styles/withStyles';
import type { Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Parallax } from 'react-scroll-parallax';
import Image from 'gatsby-image';

import SectionWrapper from '../components/SectionWrapper';
import PageHelmet from '../plugins/helmet/components/PageHelmet';
import NodeBody from '../plugins/prismic/components/NodeBody';
import BlogEntryTextSlice from '../components/BlogEntryTextSlice';
import BlogEntryImageSlice from '../components/BlogEntryImageSlice';
import formatBlogEntryDate from '../helpers/formatBlogEntryDate';
import parseDate from '../helpers/parseDate';

import type { Query } from '../schema.flow';

const styles = (theme: Theme) => ({
  root: {},
  wrapper: {},
  limitWidth: {
    maxWidth: '70vw',
    margin: 'auto',
  },
  text: {
    textTransform: 'uppercase',
  },
  parallaxContainer: {
    width: '100%',
    height: '600px',
    overflow: 'hidden',
    [theme.breakpoints.down('sm')]: {
      maxHeight: '300px',
    },
  },
  image: {
    width: '100%',
    height: '100%',
  },
});

export type Props = {
  classes: { [$Keys<$Call<typeof styles, Theme>>]: string },
  data: Query,
};

const BlogEntryPage = ({ classes, data: query }: Props) => {
  const date =
    query.prismicBlogEntry && query.prismicBlogEntry.first_publication_date
      ? parseDate(query.prismicBlogEntry.first_publication_date)
      : null;
  const fullDate = date
    ? `Published on ${formatBlogEntryDate({ date })}`
    : null;

  return (
    <SectionWrapper>
      <PageHelmet data={query.prismicBlogEntry?.data} />
      <SectionWrapper
        limitWidth
        guttersH
        guttersV
        classes={{ limitWidth: classes.limitWidth }}
      >
        <div>
          {query.prismicBlogEntry?.data?.title?.text ? (
            <Typography
              variant="h3"
              align="center"
              className={classes.text}
              gutterBottom
            >
              {query.prismicBlogEntry?.data?.title?.text}
            </Typography>
          ) : null}
          <Typography variant="h6" align="center" className={classes.text}>
            {fullDate}
          </Typography>
        </div>
      </SectionWrapper>
      <SectionWrapper>
        <div className={classes.parallaxContainer}>
          <Parallax y={[-45, 20]}>
            {query.prismicBlogEntry?.data?.image?.localFile?.childImageSharp ? (
              <Image
                {...query.prismicBlogEntry?.data?.image?.localFile
                  ?.childImageSharp}
                className={classes.image}
              />
            ) : null}
          </Parallax>
        </div>
      </SectionWrapper>
      <SectionWrapper
        limitWidth
        guttersH
        guttersV
        classes={{ limitWidth: classes.limitWidth }}
      >
        <div>
          <NodeBody
            node={query.prismicBlogEntry}
            components={{
              text: BlogEntryTextSlice,
              image: BlogEntryImageSlice,
            }}
          />
        </div>
      </SectionWrapper>
    </SectionWrapper>
  );
};

export default withStyles(styles)(BlogEntryPage);

export const query = graphql`
  query BlogEntryPage($prismicId: String) {
    prismicBlogEntry(prismicId: { eq: $prismicId }) {
      id
      lang
      first_publication_date
      data {
        meta_title {
          text
        }
        meta_description {
          text
        }
        meta_keywords {
          meta_keyword {
            text
          }
        }
        title {
          text
        }
        image {
          localFile {
            id
            childImageSharp {
              fluid(maxWidth: 1200, quality: 82) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        body {
          ... on Node {
            id
          }
          ... on PrismicBlogEntryBodyText {
            prismicId
            slice_type
            primary {
              text {
                html
              }
            }
          }
          ... on PrismicBlogEntryBodyImage {
            prismicId
            slice_type
            primary {
              simple_image {
                localFile {
                  id
                  childImageSharp {
                    fluid(maxWidth: 800, quality: 82) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
