// @flow
import * as React from 'react';
import compact from 'lodash/compact';

import AppHelmet from './AppHelmet';

type MetaKeywordData = { meta_keyword?: ?{ [string]: any, text?: ?string } };

export type Props = {
  data?: ?{
    meta_title?: ?{ [string]: ?any, text?: ?string },
    meta_description?: ?{ [string]: ?any, text?: ?string },
    meta_keywords?: ?Array<?MetaKeywordData>,
  },
};

const PageHelmet = ({ data, ...props }: Props) => (
  <AppHelmet
    title={data?.meta_title?.text || undefined}
    description={data?.meta_description?.text || undefined}
    keywords={compact(
      (data?.meta_keywords || []).map(
        (keywordData: ?MetaKeywordData) => keywordData?.meta_keyword?.text,
      ),
    )}
    {...props}
  />
);

PageHelmet.defaultProps = {
  data: undefined,
};

export default PageHelmet;
