// @flow

export type Config = {|
  date: Date,
  yearFirst?: boolean,
  separator?: string,
|};

const formatBlogEntryDate = ({
  date,
  yearFirst,
  separator = '.',
}: Config): string => {
  const parts = [`0${date.getMonth() + 1}`.slice(-2), date.getFullYear()];

  if (yearFirst) {
    parts.reverse();
  }

  return parts.join(separator);
};

export default formatBlogEntryDate;
