// @flow

// Because of Safari and iOS
// This method ignores the timezone.
const parseDate = (dateString: string): Date => {
  const splitDate = dateString.split(/[^0-9]/);
  return new Date(
    parseInt(splitDate[0], 10),
    parseInt(splitDate[1], 10) - 1,
    parseInt(splitDate[2], 10),
    parseInt(splitDate[3], 10),
    parseInt(splitDate[4], 10),
    parseInt(splitDate[5], 10),
  );
};

export default parseDate;