// @flow

import * as React from 'react';
import { withStyles } from '@material-ui/core/styles';
import type { Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import classnames from 'classnames';

import RichText from '../plugins/prismic/components/RichText';
import type { PrismicBlogEntryBodyText } from '../schema.flow';

const styles = (theme: Theme) => ({
  root: {
    paddingTop: theme.spacing.unit * 5,
    paddingBottom: theme.spacing.unit * 5,
    maxWidth: '750px',
  },
  text: {
    letterSpacing: '1px',
  },
});

export type Props = {
  data: PrismicBlogEntryBodyText,
  className?: string,
  classes: { [$Keys<$Call<typeof styles, Theme>>]: string },
};

const BlogEntryTextSlice = ({ className, classes, data }: Props) =>
  RichText.hasValue(data.primary?.text) ? (
    <div className={classnames(className, classes.root)}>
      <Typography
        component="div"
        variant="body1"
        align="left"
        className={classes.text}
      >
        <RichText {...data.primary?.text || {}} />
      </Typography>
    </div>
  ) : null;

BlogEntryTextSlice.defaultProps = {
  className: undefined,
};

export default withStyles(styles)(BlogEntryTextSlice);
