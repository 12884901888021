// @flow
import * as React from 'react';
import Image from 'gatsby-image';

import type { File } from '../../../schema.flow';

type ImageData = {
  dimensions?: ?{ width?: ?number, height?: ?number },
  alt?: ?string,
  copyright?: ?string,
  url?: ?string,
  localFile?: ?File,
};

type Props = {
  ...$Exact<
    $Diff<
      React.ElementConfig<typeof Image>,
      {
        resolutions: any,
        sizes: any,
        fixed: any,
        fluid: any,
      },
    >,
  >,
  data: ?ImageData,
};

const NodeImage = ({ data, ...props }: Props) =>
  data && data.localFile && data.localFile.childImageSharp ? (
    <Image
      alt={data.alt || ''}
      {...props}
      // $FlowFixMe --> Reason: trust that the proper fragment has been used on the query
      resolutions={data.localFile.childImageSharp.resolutions}
      // $FlowFixMe --> Reason: trust that the proper fragment has been used on the query
      fixed={data.localFile.childImageSharp.fixed}
      // $FlowFixMe --> Reason: trust that the proper fragment has been used on the query
      fluid={data.localFile.childImageSharp.fluid}
      // $FlowFixMe --> Reason: trust that the proper fragment has been used on the query
      sizes={data.localFile.childImageSharp.sizes}
    />
  ) : null;

export default NodeImage;
