// @flow

import * as React from 'react';

import NodeImage from '../plugins/prismic/components/NodeImage';
import type { PrismicBlogEntryBodyImage } from '../schema.flow';

type Props = {
  data: PrismicBlogEntryBodyImage,
};

const BlogEntryImageSlice = ({ data }: Props) =>
  data.primary && data.primary.simple_image ? (
    <NodeImage data={data.primary.simple_image} />
  ) : null;

export default BlogEntryImageSlice;

