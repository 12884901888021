// @flow

import * as React from 'react';
import classnames from 'classnames';
import withStyles from '@material-ui/core/styles/withStyles';
import type { Theme } from '@material-ui/core/styles';
import {
  Element,
} from 'react-scroll';

export type Props = {
  name?: string,
  limitWidth?: boolean,
  guttersH?: boolean,
  guttersV?: boolean,
  children: React.Node,
  justifyRight?: boolean,
  classes: {
    [
      | 'root'
      | 'wrapper'
      | 'page'
      | 'limitWidth'
      | 'justifyContentCenter'
      | 'justifyContentRight'
      | 'guttersH'
      | 'guttersV']: string,
  },
};

export const curryLimitWidthClasses = (name: string = 'limitWidth') => (
  theme: Theme,
) => ({
  [name]: {
    maxWidth: '90vw',
    width: '100%',
  },
  [theme.breakpoints.up('sm')]: {
    [name]: {
      maxWidth: '90vw',
      width: '100%',
    },
  },
  [theme.breakpoints.up('md')]: {
    [name]: {
      maxWidth: '90vw',
      width: '100%',
    },
  },
  [theme.breakpoints.up('lg')]: {
    [name]: {
      maxWidth: '85vw',
      width: '100%',
    },
  },
  [theme.breakpoints.up('xl')]: {
    [name]: {
      maxWidth: '70vw',
      width: '100%',
    },
  },
});

export const styles = (theme: Theme) => ({
  root: {
    width: '100%',
    display: 'flex',
    flex: '1',
    position: 'relative',
    paddingLeft: 80,
    [theme.breakpoints.down('xs')]: {
      paddingLeft: 50,
    },
    '& $root': {
      paddingLeft: 0,
    },
  },
  wrapper: {},
  ...curryLimitWidthClasses()(theme),
  page: {
    width: '100%',
  },
  justifyContentCenter: {
    justifyContent: 'center',
  },
  justifyContentRight: {
    [theme.breakpoints.up('md')]: {
      justifyContent: 'flex-end',
    },
  },
  guttersH: theme.mixins.gutters(),
  guttersV: {
    paddingTop: theme.spacing.unit * 10,
    paddingBottom: theme.spacing.unit * 10,
  },
});

const SectionWrapper = ({
  classes,
  limitWidth,
  guttersH,
  guttersV,
  name,
  children,
  justifyRight,
}: Props): React.Node => (
  <Element
    name={name}
    className={classnames(classes.page, classes.root, {
      [classes.justifyContentCenter]: limitWidth,
      [classes.justifyContentRight]: justifyRight,
    })}
  >
    <div
      className={classnames(classes.wrapper, {
        [classes.page]: !limitWidth,
        [classes.limitWidth]: limitWidth,
        [classes.guttersH]: guttersH,
        [classes.guttersV]: guttersV,
      })}
    >
      {children}
    </div>
  </Element>
);

SectionWrapper.defaultProps = {
  name: undefined,
  limitWidth: false,
  guttersH: false,
  guttersV: false,
  justifyRight: false,
};

export default withStyles(styles)(SectionWrapper);
