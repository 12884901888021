// @flow
import * as React from 'react';

import type { Node, Scalars, Internal } from '../../../schema.flow';

type BodySlice = Node & {
  id: $ElementType<Scalars, 'ID'>,
  parent?: ?Node,
  children: Array<Node>,
  internal: Internal,
  slice_type?: ?$ElementType<Scalars, 'String'>,
  primary?: ?any,
  prismicId?: ?$ElementType<Scalars, 'String'>,
};

type NodeWithBody = Node & {
  data?: ?{
    [string]: any,
    body?: ?Array<?BodySlice>,
  },
};

export type Props = {
  node: ?NodeWithBody,
  components: {
    [string]: React.ComponentType<{
      [string]: any,
      node: NodeWithBody,
      data: BodySlice,
    }>,
  },
};

/**
 * Render body slices for the given node, using the provided components in
 * the `components` dictionary prop, retrieved by each slice's `slice_type`.
 */
const BodySlices = ({ node, components }: Props) =>
  node && node.data && node.data.body ? (
    <>
      {node.data.body.map((slice, index) => {
        const { prismicId, slice_type: sliceType } = slice || {};
        if (slice && sliceType && components[sliceType]) {
          const Component = components[sliceType];
          return (
            <Component key={prismicId || index} data={slice} node={node} />
          );
        }
        return null;
      })}
    </>
  ) : null;

export default BodySlices;
